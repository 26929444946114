import React from 'react';

const Projects = () => {
  const handleFileSelect = (event) => {
    const files = event.target.files;
    console.log('Selected files:', files);
    // Здесь вы можете обрабатывать выбранные файлы
  };

  const handleFolderSelect = (event) => {
    const files = event.target.files;
    console.log('Selected folder:', files);
    // Здесь вы можете обрабатывать выбранную папку
  };

  return (
    <div>
      <h2>Projects</h2>
      <input
        type="file"
        id="file-input"
        style={{ display: 'none' }}
        onChange={handleFileSelect}
      />
      <button onClick={() => document.getElementById('file-input').click()}>
        Open File
      </button>
      <input
        type="file"
        id="folder-input"
        style={{ display: 'none' }}
        webkitdirectory=""
        mozdirectory=""
        onChange={handleFolderSelect}
      />
      <button onClick={() => document.getElementById('folder-input').click()}>
        Open Folder
      </button>
    </div>
  );
};

export default Projects;
