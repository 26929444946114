import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TableFooter,
  TablePagination,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Drawer,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  SpeedDial,
  SpeedDialAction,
} from '@mui/material';
import {
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  HourglassEmpty as HourglassEmptyIcon,
  PauseCircleOutline as PauseCircleOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Settings as SettingsIcon,
  Delete as DeleteForeverIcon,
  Add as AddIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/de';
import { LocalizationProvider, DateRangePicker, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import '../App.css';
import './Home.css';

import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

import Backdrop from '@mui/material/Backdrop';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const API_URL = "https://us-central1-vigilant-mix-403621.cloudfunctions.net/api"

const theme = createTheme({
  palette: {
    white_custom: {
      main: 'white', // Основной цвет
    },
  },
});


const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.max(0, Math.ceil(count / rowsPerPage) - 1)}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

// #############################################

const FilteredTable = ({ projects, onEdit, tabIndex }) => {
  const [filterInput, setFilterInput] = useState({
    id: '',
    bauvorhaben: '',
    kostenstelle_vvo: '',
    ansprechpartner: '',
    ort: '',
    strasse: '',
    hverbor_datum: '',
    ausfurung_von: '',
    umbau_datum: '',
    ausfurung_bis: ''
  });

  const handleOpenFolder = (kostenstelle) => {
    // Разбиваем текст по знаку "-"
    const parts = kostenstelle.split("-");
    let basePath = "";

    // Определяем путь на основе части после знака "-"
    if (parts.length > 1) {
      const yearPart = parts[1];
      if (yearPart.includes("24")) {
        basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2024\\02 Verkehrssicherung"; // для локального тестирования
      } else if (yearPart.includes("25")) {
        basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2025\\02 Verkehrssicherung";
      } else if (yearPart.includes("23")) {
        basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2023\\02 Verkehrssicherung";
      } else if (yearPart.includes("22")) {
        basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2022\\02 Verkehrssicherung";
      } else if (yearPart.includes("21")) {
        basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2021\\02 Verkehrssicherung";
      } else if (yearPart.includes("20")) {
        basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2020\\02 Verkehrssicherung";
      } else if (yearPart.includes("26")) {
        basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2026\\02 Verkehrssicherung";
      } else if (yearPart.includes("27")) {
        basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2027\\02 Verkehrssicherung";
      } else if (yearPart.includes("28")) {
        basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2028\\02 Verkehrssicherung";
      } else {
        // По умолчанию
        basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2024\\02 Verkehrssicherung";
      }
    } else {
      // По умолчанию, если нет знака "-"
      basePath = "\\\\FILESRV1\\Abteilungen\\VVO\\2024\\02 Verkehrssicherung";
    }

    // Заменяем все обратные косые черты на прямые
    const normalizedBasePath = basePath.replace(/\\/g, '/');

    // Формируем полный путь для открытия папки с использованием кастомного протокола
    const folderPath = `myapp://${normalizedBasePath}/${kostenstelle}`;
    window.open(folderPath, '_blank');
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterInput(prev => ({ ...prev, [name]: value }));
  };

  const filteredData = React.useMemo(() => {
    return projects.filter(project => {
      return (
        (project.id?.toString().includes(filterInput.id)) &&
        (project.bauvorhaben?.toLowerCase().includes(filterInput.bauvorhaben.toLowerCase())) &&
        (project.kostenstelle_vvo?.toLowerCase().includes(filterInput.kostenstelle_vvo.toLowerCase())) &&
        (project.ansprechpartner?.toLowerCase().includes(filterInput.ansprechpartner.toLowerCase())) &&
        (project.ort?.toLowerCase().includes(filterInput.ort.toLowerCase())) &&
        (project.strasse?.toLowerCase().includes(filterInput.strasse.toLowerCase())) &&
        (project.hverbor_datum?.toLowerCase().includes(filterInput.hverbor_datum.toLowerCase())) &&
        (project.ausfurung_von?.toLowerCase().includes(filterInput.ausfurung_von.toLowerCase())) &&
        (project.umbau_datum?.toLowerCase().includes(filterInput.umbau_datum.toLowerCase())) &&
        (project.ausfurung_bis?.toLowerCase().includes(filterInput.ausfurung_bis.toLowerCase()))
      );
    });
  }, [projects, filterInput]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ value }) => (
          <span style={{ display: 'block', maxWidth: '50px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {value}
          </span>
        ),
      },
      {
        Header: 'Bauvorhaben',
        accessor: 'bauvorhaben',
        Cell: ({ value }) => (
          <Tooltip title={value.length > 40 ? value : ''} placement="top" arrow disableHoverListener={value.length <= 40}>
            <span style={{ textAlign: 'left', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '300px' }}>
              {value.length > 40 ? `${value.substring(0, 40)}...` : value}

            </span>
          </Tooltip>
        ),
      },
      {
        Header: 'Kostenstelle VVO',
        accessor: 'kostenstelle_vvo',
        Cell: ({ value }) => (
          <span
            style={{ cursor: 'pointer', color: 'black', textDecoration: 'underline' }}
            onClick={() => handleOpenFolder(value)}
          >
            {value}
          </span>
        ),
      },
      {
        Header: 'Ansprechpartner',
        accessor: 'ansprechpartner',
        Cell: ({ value }) => (
          <Tooltip title={value.length > 20 ? value : ''} placement="top" arrow disableHoverListener={value.length <= 20}>
            <span style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '150px' }}>
              {value}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: 'Ort',
        accessor: 'ort',
        Cell: ({ value }) => (
          <Tooltip title={value.length > 15 ? value : ''} placement="top" arrow disableHoverListener={value.length <= 15}>
            <span style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100px' }}>
              {value}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: 'Straße',
        accessor: 'strasse',
        Cell: ({ value }) => (
          <Tooltip title={value.length > 20 ? value : ''} placement="top" arrow disableHoverListener={value.length <= 20}>
            <span style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '150px' }}>
              {value}
            </span>
          </Tooltip>
        ),
      },
      { Header: 'H.verbot Datum', accessor: 'hverbor_datum' },
      { Header: 'Ausführung Von', accessor: 'ausfurung_von' },
      { Header: 'Umbau Datum', accessor: 'umbau_datum' },
      { Header: 'Ausführung Bis', accessor: 'ausfurung_bis' },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <Box className="actionButtons" sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
            <IconButton aria-label="edit" onClick={() => onEdit(row.original)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="photo">
              <InsertPhotoIcon />
            </IconButton>
            <IconButton aria-label="material">
              <ProductionQuantityLimitsIcon />
            </IconButton>
            <IconButton aria-label="more">
              <MoreVertIcon />
            </IconButton>
            <IconButton aria-label="settings">
              <SettingsIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    [onEdit]
  );

  const sortedData = React.useMemo(() => {
    const pastProjects = filteredData.filter(project => moment(project.ausfurung_von, 'DD.MM.YYYY').isBefore(moment().startOf('day')));
    const futureProjects = filteredData.filter(project => !moment(project.ausfurung_von, 'DD.MM.YYYY').isBefore(moment().startOf('day')));

    futureProjects.sort((a, b) => {
      const dateA = moment(a.ausfurung_von, 'DD.MM.YYYY');
      const dateB = moment(b.ausfurung_von, 'DD.MM.YYYY');
      return dateA - dateB;
    });

    pastProjects.sort((a, b) => {
      const dateABis = moment(a.ausfurung_bis, 'DD.MM.YYYY');
      const dateBBis = moment(b.ausfurung_bis, 'DD.MM.YYYY');
      return dateABis - dateBBis;
    });

    return [...futureProjects, ...pastProjects];
  }, [filteredData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
  } = useTable(
    { columns, data: sortedData, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination
  );

  return (
    <>
      <Paper component={Paper} sx={{ maxHeight: '75vh', overflow: 'auto', boxShadow: 12, padding: 0.5, marginBottom: 0.5, background: "grey" }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'minmax(50px, 100%) minmax(310px, 100%) minmax(160px, 100%) minmax(170px, 100%) minmax(100px, 100%) minmax(180px, 100%) minmax(150px, 100%) minmax(170px, 100%) minmax(130px, 100%) minmax(180px, 100%) minmax(240px,100%)', // Используем minmax и проценты
            gap: 1,
            alignItems: 'center', // Выравнивание элементов по вертикали
          }}
        >
          {[
            { name: 'id', placeholder: 'ID' },
            { name: 'bauvorhaben', placeholder: 'Bauvorhaben' },
            { name: 'kostenstelle_vvo', placeholder: 'Kostenstelle VVO' },
            { name: 'ansprechpartner', placeholder: 'Ansprechpartner' },
            { name: 'ort', placeholder: 'Ort' },
            { name: 'strasse', placeholder: 'Straße' },
            { name: 'hverbor_datum', placeholder: 'H.verbot Datum' },
            { name: 'ausfurung_von', placeholder: 'Ausführung Von' },
            { name: 'umbau_datum', placeholder: 'Umbau Datum' },
            { name: 'ausfurung_bis', placeholder: 'Ausführung Bis' }
          ].map((field, index) => (
            <TextField
              key={index}
              name={field.name}
              value={filterInput[field.name]}
              onChange={handleFilterChange}
              placeholder={field.placeholder}
              variant="outlined"
              size="small"
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '&:hover': {
                    cursor: 'pointer', // Изменяет курсор при наведении на область ввода
                  },
                },
                '& .MuiInputBase-input': {
                  cursor: 'pointer', // Изменяет курсор при наведении на текстовую часть
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: 'customOutlinedInput', // Применение пользовательского класса для обводки
                },
                sx: {
                  '& input::placeholder': {
                    color: 'black', // Цвет текста placeholder
                    fontSize: '14px', // Размер шрифта placeholder
                  },

                },
              }}
            />
          ))}
          {/* Ячейка с кнопкой для очистки полей ввода */}
          <Box sx={{ gridColumn: 'span 1', textAlign: 'left' }}>
            <IconButton
              sx={{
                color: '#424242' // Темно-серый цвет

              }}
              onClick={() => setFilterInput({
                id: '',
                bauvorhaben: '',
                kostenstelle_vvo: '',
                ansprechpartner: '',
                ort: '',
                strasse: '',
                hverbor_datum: '',
                ausfurung_von: '',
                umbau_datum: '',
                ausfurung_bis: ''
              })}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        </Box>
      </Paper>

      <TableContainer component={Paper} className="table-container" sx={{ boxShadow: 12 }}>
        <Table {...getTableProps()} stickyHeader>
          <TableHead
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 900,
              backgroundColor: 'grey',
            }}
          >
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="table-cell-header"
                  >
                    {column.render('Header')}
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              const ausfurungVon = moment(row.values.ausfurung_von, 'DD.MM.YYYY');
              const ausfurungBis = moment(row.values.ausfurung_bis, 'DD.MM.YYYY');
              const hverborDatum = moment(row.values.hverbor_datum, 'DD.MM.YYYY');
              const daysLeftVon = ausfurungVon.diff(moment(), 'days');
              const daysLeftBis = ausfurungBis.diff(moment(), 'days');
              const daysLeftHverbor = hverborDatum.diff(moment(), 'days');
              const today = moment().startOf('day');
              const nextMonday = today.clone().isoWeekday(8); // Следующий понедельник
              const endOfNextWeek = nextMonday.clone().add(6, 'days'); // Конец следующей недели
              const endOfWeek = today.clone().endOf('isoWeek');
              const isNextWeek = ausfurungVon.isBetween(nextMonday, endOfNextWeek, 'days', '[]');
              const isWithinThreeDays = daysLeftVon <= 3 && daysLeftVon == 0;
              const isThisWeek = ausfurungVon.isBetween(today, endOfWeek, 'days', '[]');
              const isToday = ausfurungVon.isSame(today, 'day');
              const isPastDate = daysLeftVon < 0;
              const isBeyondOneWeek = daysLeftVon >= 7;

              let backgroundColor = 'white';
              let textColor = 'black';
              if (tabIndex === 0) {
                if (isToday) {
                  backgroundColor = 'red';
                  textColor = 'white';
                } else if (isThisWeek) {
                  backgroundColor = 'pink';
                } else if (isNextWeek) {
                  backgroundColor = 'orange';
                } else if (isPastDate) {
                  backgroundColor = 'green';
                } else if (isBeyondOneWeek) {
                  backgroundColor = 'lightgrey';
                }
              } else {
                backgroundColor = rowIndex % 2 === 0 ? '#d3d3d3' : '#c0c0c0';
              }

              return (
                <TableRow
                  {...row.getRowProps()}
                  className="tableRow"
                  sx={{
                    '&:hover': {
                      boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.7), 0px -20px 40px rgba(0, 0, 0, 0.7)',
                      zIndex: 10,
                      position: 'relative',
                    },
                  }}
                  style={{
                    backgroundColor,
                    color: textColor,
                  }}
                >
                  {row.cells.map(cell => (

                    <TableCell
                      {...cell.getCellProps()}
                      style={{
                        fontWeight: 'bold',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        minWidth: `${100 / columns.length}%`,
                        padding: '0px',
                        height: '30px',
                        backgroundColor: tabIndex === 0 && cell.column.id === 'ausfurung_von' && isWithinThreeDays ? 'red' :
                          tabIndex === 0 && cell.column.id === 'ausfurung_bis' && daysLeftBis < 7 && daysLeftBis >= 0 ? 'red' :
                            tabIndex === 0 && cell.column.id === 'hverbor_datum' && daysLeftHverbor < 3 && daysLeftHverbor >= 0 ? 'red' :
                              backgroundColor,
                        color: backgroundColor === 'red' || (tabIndex === 0 && cell.column.id === 'ausfurung_von' && isWithinThreeDays) ||
                          (tabIndex === 0 && cell.column.id === 'ausfurung_bis' && daysLeftBis < 7 && daysLeftBis >= 0) ||
                          (tabIndex === 0 && cell.column.id === 'hverbor_datum' && daysLeftHverbor < 3 && daysLeftHverbor >= 0)
                          ? 'white' : 'black',
                      }}
                    >
                      {cell.render('Cell')}
                    </TableCell>

                  ))}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter
            sx={{
              position: 'sticky',
              bottom: 0,
              zIndex: 1000,
              backgroundColor: 'grey',
            }}
          >
            <TableRow>
              <TableCell colSpan={columns.length} style={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1, padding: 0 }}>
                      <Box sx={{ width: 15, height: 15, backgroundColor: 'red', marginRight: 0.5, marginLeft: 0.5 }} />
                      <span style={{ color: 'black', fontWeight: 'bold' }}>Heute</span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1, padding: 0 }}>
                      <Box sx={{ width: 15, height: 15, backgroundColor: 'pink', marginRight: 0.5 }} />
                      <span style={{ color: 'black', fontWeight: 'bold' }}>Diese Woche</span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1, padding: 0 }}>
                      <Box sx={{ width: 15, height: 15, backgroundColor: 'orange', marginRight: 0.5 }} />
                      <span style={{ color: 'black', fontWeight: 'bold' }}>Nächste Woche</span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1, padding: 0 }}>
                      <Box sx={{ width: 15, height: 15, backgroundColor: 'white', marginRight: 0.5 }} />
                      <span style={{ color: 'black', fontWeight: 'bold' }}>Nach mehr als einer Wochee</span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1, padding: 0 }}>
                      <Box sx={{ width: 15, height: 15, backgroundColor: 'green', marginRight: 0.5 }} />
                      <span style={{ color: 'black', fontWeight: 'bold' }}>Fertiggestellte Baustellee</span>
                    </Box>
                  </Box>
                  <Box sx={{ flexGrow: 1 }} />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length}
                    count={sortedData.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={(event, newPage) => gotoPage(newPage)}
                    onRowsPerPageChange={(event) => {
                      setPageSize(Number(event.target.value));
                      gotoPage(0);
                    }}
                    ActionsComponent={TablePaginationActions}
                    sx={{ border: 'none', margin: 0, padding: 0 }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

// #############################################

// Использование обновленного компонента FilteredTable в компоненте Home
const Home = () => {
  const [projects, setProjects] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [newProject, setNewProject] = useState({
    bauvorhaben: '',
    kostenstelle_vvo: '',
    ort: '',
    strasse: '',
    ausfurung_von: '',
    ausfurung_bis: '',
    ansprechpartner: '',
    umbau_datum: '',
    status: 'Aktiv' // Статус по умолчанию
  }); // Состояние для нового проекта
  const [showUmbauDatum, setShowUmbauDatum] = useState(false); // Для чекбокса
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  
  useEffect(() => {
    axios.get(`${API_URL}/projects`)
      .then(response => {
        const projects = response.data.map(project => ({
          ...project,
          ausfurung_von_moment: moment(project.ausfurung_von, 'DD.MM.YYYY'),
          hverbor_datum_moment: moment(project.hverbor_datum, 'DD.MM.YYYY')
        }));
        setProjects(projects);
      })
      .catch(error => {
        console.error('There was an error fetching the projects!', error);
      });
  }, []);

  // Добавляем компонент SpeedDial для отображения кнопки "Создать новую стройку"
  const ActionSpeedDial = ({ onCreate }) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
      <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
        <Backdrop open={open} sx={{ zIndex: (theme) => theme.zIndex.speedDial - 1 }} />
        <SpeedDial
          ariaLabel="SpeedDial"
          sx={{
            position: 'absolute', // Позиция на экране
            bottom: 16,
            right: 16,
            '& .MuiFab-primary': {
              backgroundColor: 'red', // Изменение фона основного элемента
              '&:hover': {
                backgroundColor: 'darkred', // Фон при наведении
              },
            },
          }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          <SpeedDialAction
            key="create"
            icon={<AddIcon />}
            tooltipTitle="Neue Baustelle erstellen"
            onClick={() => {
              handleClose();
              onCreate();
            }}
          />
        </SpeedDial>
      </Box>
    );
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const filteredProjects = (status) => {
    return projects.filter(project => project.status === status);
  };

  const handleEdit = (project) => {
    setCurrentProject(project);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setCurrentProject(null);
    setNewProject({}); // Очистка состояния нового проекта
  };

  const handleSave = () => {
    if (currentProject) {
      axios.put(`${API_URL}/projects/${currentProject.id}`, currentProject)
        .then(response => {
          axios.get(`${API_URL}/projects`)
            .then(response => {
              const projects = response.data.map(project => ({
                ...project,
                ausfurung_von_moment: moment(project.ausfurung_von, 'DD.MM.YYYY'),
                hverbor_datum_moment: moment(project.hverbor_datum, 'DD.MM.YYYY')
              }));
              setProjects(projects);
              setAlertSeverity('success');
              setAlertMessage('Projekt erfolgreich aktualisiert!');
              setOpenSnackbar(true);
              handleCloseDrawer();
            })
            .catch(error => {
              setAlertSeverity('error');
              setAlertMessage('Fehler beim Abrufen der Projekte!');
              setOpenSnackbar(true);
              console.error('There was an error fetching the projects!', error);
            });
        })
        .catch(error => {
          setAlertSeverity('error');
          setAlertMessage('Fehler beim Aktualisieren des Projekts!');
          setOpenSnackbar(true);
          console.error('There was an error updating the project!', error);
        });
    } else {
      axios.post(`${API_URL}/projects`, newProject)
        .then(response => {
          axios.get(`${API_URL}/projects`)
            .then(response => {
              const projects = response.data.map(project => ({
                ...project,
                ausfurung_von_moment: moment(project.ausfurung_von, 'DD.MM.YYYY'),
                hverbor_datum_moment: moment(project.hverbor_datum, 'DD.MM.YYYY')
              }));
              setProjects(projects);
              setAlertSeverity('success');
              setAlertMessage('Projekt erfolgreich erstellt!');
              setOpenSnackbar(true);
              handleCloseDrawer();
            })
            .catch(error => {
              setAlertSeverity('error');
              setAlertMessage('Fehler beim Abrufen der Projekte!');
              setOpenSnackbar(true);
              console.error('There was an error fetching the projects!', error);
            });
        })
        .catch(error => {
          setAlertSeverity('error');
          setAlertMessage('Fehler beim Erstellen des Projekts!');
          setOpenSnackbar(true);
          console.error('There was an error creating the project!', error);
        });
    }
  };
  

  const handleCreateNewProject = () => {
    setDrawerOpen(true);
    setCurrentProject(null);
    setNewProject({
      bauvorhaben: '',
      kostenstelle_vvo: '',
      ort: '',
      strasse: '',
      ausfurung_von: '',
      ausfurung_bis: '',
      ansprechpartner: '',
      umbau_datum: '',
    });
  };

  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        centered
        TabIndicatorProps={{
          style: {
            backgroundColor: 'white',
            marginTop: 0,
          },
        }}
      >
        <Tab
          icon={<HourglassEmptyIcon />}
          iconPosition="start"
          label="Aktiv"
          sx={{
            color: 'grey',
            fontWeight: 'bold',
            '&.Mui-selected': {
              color: 'white',
            },
          }}
        />
        <Tab
          icon={<PauseCircleOutlineIcon />}
          label="Inaktiv"
          iconPosition="start"
          sx={{
            color: 'grey',
            fontWeight: 'bold',
            '&.Mui-selected': {
              color: 'white',
            },
          }}
        />
        <Tab
          icon={<CheckCircleOutlineIcon />}
          label="Abgeschlossen"
          iconPosition="start"
          sx={{
            color: 'grey',
            fontWeight: 'bold',
            '&.Mui-selected': {
              color: 'white',
            },
          }}
        />
      </Tabs>

      {tabIndex === 0 && <FilteredTable projects={filteredProjects('Aktiv')} onEdit={handleEdit} tabIndex={tabIndex} />}
      {tabIndex === 1 && <FilteredTable projects={filteredProjects('Inaktiv')} onEdit={handleEdit} tabIndex={tabIndex} />}
      {tabIndex === 2 && <FilteredTable projects={filteredProjects('Abgeschlossen')} onEdit={handleEdit} tabIndex={tabIndex} />}

      <ActionSpeedDial onCreate={handleCreateNewProject} />

      <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#222222' } }}>
        <Box sx={{ width: 350, padding: 2 }}>
          {currentProject || newProject ? (
            <>
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel
                  sx={{
                    top: '-6px', // Поднимите лейбл немного выше
                    color: 'white',
                    '&.Mui-focused': {
                      color: 'white', // Цвет лейбла при фокусе
                    },
                    '&.MuiInputLabel-shrink': {
                      color: 'white', // Цвет лейбла при открытии списка
                    }
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  value={currentProject ? currentProject.status : newProject.status}
                  onChange={(e) => {
                    if (currentProject) {
                      setCurrentProject({ ...currentProject, status: e.target.value });
                    } else {
                      setNewProject({ ...newProject, status: e.target.value });
                    }
                  }}
                  label="Status"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: 'black', // Фон выпадающего списка
                        color: 'white', // Цвет текста в выпадающем списке
                      },
                    },
                  }}
                  sx={{
                    height: '40px', // Высота как у остальных полей ввода
                    color: 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#b3b3b3',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white',
                    },
                  }}
                >
                  <MenuItem value="Aktiv">Aktiv</MenuItem>
                  <MenuItem value="Inaktiv">Inaktiv</MenuItem>
                  <MenuItem value="Abgeschlossen">Abgeschlossen</MenuItem>
                </Select>
              </FormControl>


              <TextField
                label="Kostenstelle VVO"
                fullWidth
                margin="normal"
                value={currentProject ? currentProject.kostenstelle_vvo : newProject.kostenstelle_vvo}
                onChange={(e) => {
                  if (currentProject) {
                    setCurrentProject({ ...currentProject, kostenstelle_vvo: e.target.value });
                  } else {
                    setNewProject({ ...newProject, kostenstelle_vvo: e.target.value });
                  }
                }}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    top: '-6px', // Поднимите лейбл немного выше
                  },
                  style: {
                    color: 'white',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#b3b3b3',
                    },
                    '&:hover fieldset': {
                      borderColor: 'red',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                    '& input': {
                      color: 'white',
                    }
                  },
                }}
              />
              <TextField
                label="Bauvorhaben"
                fullWidth
                margin="normal"
                value={currentProject ? currentProject.bauvorhaben : newProject.bauvorhaben}
                onChange={(e) => {
                  if (currentProject) {
                    setCurrentProject({ ...currentProject, bauvorhaben: e.target.value });
                  } else {
                    setNewProject({ ...newProject, bauvorhaben: e.target.value });
                  }
                }}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    top: '-6px', // Поднимите лейбл немного выше
                  },
                  style: {
                    color: 'white',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#b3b3b3',
                    },
                    '&:hover fieldset': {
                      borderColor: 'red',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                    '& input': {
                      color: 'white',
                    }
                  },
                }}
              />
              <TextField
                label="Ort"
                fullWidth
                margin="normal"
                value={currentProject ? currentProject.ort : newProject.ort}
                onChange={(e) => {
                  if (currentProject) {
                    setCurrentProject({ ...currentProject, ort: e.target.value });
                  } else {
                    setNewProject({ ...newProject, ort: e.target.value });
                  }
                }}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    top: '-6px', // Поднимите лейбл немного выше
                  },
                  style: {
                    color: 'white',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#b3b3b3',
                    },
                    '&:hover fieldset': {
                      borderColor: 'red',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                    '& input': {
                      color: 'white',
                    }
                  },
                }}
              />
              <TextField
                label="Straße"
                fullWidth
                margin="normal"
                value={currentProject ? currentProject.strasse : newProject.strasse}
                onChange={(e) => {
                  if (currentProject) {
                    setCurrentProject({ ...currentProject, strasse: e.target.value });
                  } else {
                    setNewProject({ ...newProject, strasse: e.target.value });
                  }
                }}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    top: '-6px', // Поднимите лейбл немного выше
                  },
                  style: {
                    color: 'white',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#b3b3b3',
                    },
                    '&:hover fieldset': {
                      borderColor: 'red',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                    '& input': {
                      color: 'white',
                    }
                  },
                }}
              />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                  <DateRangePicker
                    slotProps={{ textField: { size: 'small', color: 'white_custom' } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#b3b3b3',
                        },
                        '&:hover fieldset': {
                          borderColor: 'red',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        },
                        '& input': {
                          color: 'white',
                        }
                      },
                    }}
                    localeText={{ start: 'Ausführung Von', end: 'Ausführung Bis' }}
                    calendars={1}
                    value={[
                      currentProject ? moment(currentProject.ausfurung_von, 'DD.MM.YYYY') : newProject.ausfurung_von ? moment(newProject.ausfurung_von, 'DD.MM.YYYY') : null,
                      currentProject ? moment(currentProject.ausfurung_bis, 'DD.MM.YYYY') : newProject.ausfurung_bis ? moment(newProject.ausfurung_bis, 'DD.MM.YYYY') : null
                    ]}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      if (currentProject) {
                        setCurrentProject({
                          ...currentProject,
                          ausfurung_von: start ? start.format('DD.MM.YYYY') : '',
                          ausfurung_bis: end ? end.format('DD.MM.YYYY') : '',
                        });
                      } else {
                        setNewProject({
                          ...newProject,
                          ausfurung_von: start ? start.format('DD.MM.YYYY') : '',
                          ausfurung_bis: end ? end.format('DD.MM.YYYY') : '',
                        });
                      }
                    }}
                    renderInput={(startProps, endProps) => (
                      <>
                        <TextField
                          {...startProps}
                          InputProps={{
                            className: 'inputField inputRoot',
                          }}
                          InputLabelProps={{
                            className: 'inputLabel',
                          }}
                        />
                        <TextField
                          {...endProps}
                          InputProps={{
                            className: 'inputField inputRoot',
                          }}
                          InputLabelProps={{
                            className: 'inputLabel',
                          }}
                        />
                      </>
                    )}
                  />
                </ThemeProvider>
              </LocalizationProvider>

              <TextField
                label="Ansprechpartner"
                fullWidth
                margin="normal"
                value={currentProject ? currentProject.ansprechpartner : newProject.ansprechpartner}
                onChange={(e) => {
                  if (currentProject) {
                    setCurrentProject({ ...currentProject, ansprechpartner: e.target.value });
                  } else {
                    setNewProject({ ...newProject, ansprechpartner: e.target.value });
                  }
                }}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    top: '-6px', // Поднимите лейбл немного выше
                  },
                  style: {
                    color: 'white',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#b3b3b3',
                    },
                    '&:hover fieldset': {
                      borderColor: 'red',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                    '& input': {
                      color: 'white',
                    }
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showUmbauDatum}
                    onChange={(e) => setShowUmbauDatum(e.target.checked)}
                    sx={{
                      color: 'white',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                    }}
                  />
                }
                label="Umbau Datum hinzufügen"
                sx={{ color: 'white' }}
              />
{showUmbauDatum && (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <ThemeProvider theme={theme}>
      <DatePicker
        slotProps={{ textField: { size: 'small', color: 'white_custom' } }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#b3b3b3',
            },
            '&:hover fieldset': {
              borderColor: 'red',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
            '& input': {
              color: 'white',
            }
          },
        }}
        label="Umbau Datum"
        value={currentProject ? moment(currentProject.umbau_datum, 'DD.MM.YYYY') : newProject.umbau_datum ? moment(newProject.umbau_datum, 'DD.MM.YYYY') : null}
        onChange={(date) => {
          if (currentProject) {
            setCurrentProject({
              ...currentProject,
              umbau_datum: date ? date.format('DD.MM.YYYY') : '',
            });
          } else {
            setNewProject({
              ...newProject,
              umbau_datum: date ? date.format('DD.MM.YYYY') : '',
            });
          }
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </ThemeProvider>
  </LocalizationProvider>
)}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </>
          ) : null}
        </Box>
      </Drawer>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
      <MuiAlert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
        {alertMessage}
      </MuiAlert>
    </Snackbar>
    </div>
  );
};

export default Home;
