import React, { useState, useEffect } from 'react'; //ирокая на широкую
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ChevronLeftIcon from '@mui/icons-material/ChevronRight';
import ChevronRightIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import PeopleIcon from '@mui/icons-material/People';
import BuildIcon from '@mui/icons-material/Build';
import Home from './components/Home';
import Projects from './components/Projects';
import Employees from './components/Employees';
import Equipment from './components/Equipment';
import './App.css';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { grey } from '@mui/material/colors'; //test11

import SignInSide from './components/SignInSide';

const drawerWidth = 240;
const drawerBleeding = 56;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#333333',
  color: '#ffffff',
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: '#222222',
  color: '#ffffff',
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? '#fff' : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function Main({ onLogout }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const [swipeableDrawerOpen, setSwipeableDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    handleMenuClose();
    onLogout();
  };

  const toggleSwipeableDrawer = (open) => () => {
    setSwipeableDrawerOpen(open);
  };

  useEffect(() => {
    const iframe = document.getElementById('google-sheets-iframe');
    if (iframe) {
      // Restore scroll position
      const scrollPosition = localStorage.getItem('scrollPosition') || 0;
      iframe.onload = () => {
        iframe.contentWindow.scrollTo(0, scrollPosition);
      };
    }

    window.addEventListener('beforeunload', () => {
      // Save scroll position
      if (iframe) {
        localStorage.setItem('scrollPosition', iframe.contentWindow.scrollY);
      }
    });
  }, []);

  const menuItems = [
    { text: 'Baustellen', path: '/', icon: <HomeIcon /> },
    { text: 'Projects', path: '/projects', icon: <WorkIcon /> },
    { text: 'Employees', path: '/employees', icon: <PeopleIcon /> },
    { text: 'Equipment', path: '/equipment', icon: <BuildIcon /> }
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: '#222222' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}></Typography>
          <IconButton onClick={toggleSwipeableDrawer(true)} sx={{ color: '#ffffff', mr: 2 }}>
            <EditCalendarIcon />
          </IconButton>
          <IconButton onClick={handleMenuOpen} sx={{ p: 0 }}>
            <Avatar alt="App Avatar" src="/images/app_ico.png" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{ mt: '45px' }}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {open ? (
            <IconButton onClick={handleDrawerClose} sx={{ color: '#ffffff' }}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen} sx={{ color: '#ffffff' }}>
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                component={Link}
                to={item.path}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  '&:hover': {
                    backgroundColor: 'darkred',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#bf0d00',
                    '&:hover': {
                      backgroundColor: 'darkred',
                    },
                  },
                }}
                selected={location.pathname === item.path}
                onClick={handleDrawerClose}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: location.pathname === item.path ? '#ffffff' : '#ffffff',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, ml: 8, pt: 3, mt: 8 }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/equipment" element={<Equipment />} />
        </Routes>
      </Box>
      <SwipeableDrawer
        anchor="bottom"
        open={swipeableDrawerOpen}
        onClose={toggleSwipeableDrawer(false)}
        onOpen={toggleSwipeableDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ zIndex: 1300 }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: 'text.secondary' }}>Google Sheets</Typography>
        </StyledBox>
        <StyledBox
          sx={{
            height: '95vh',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              height: '100%',
              transform: 'scale(1)', // Убираем увеличение
              transformOrigin: '0 0',
              width: '100%', // Обеспечиваем, что содержимое занимает всю ширину контейнера
            }}
          >
            <iframe
              id="google-sheets-iframe"
              src="https://docs.google.com/spreadsheets/d/1J_2BAPZo3GVMVVIy3chz0ba9qwwbm0HkeIUzi_cWZew/edit?usp=sharing"
              width="100%"
              height="100%"
              frameBorder="0"
              title="Google Sheets"
            ></iframe>
          </Box>
        </StyledBox>
      </SwipeableDrawer>
    </Box>
  );
}

const App = ({ isAuthenticated }) => {
  const [authState, setAuthState] = useState(isAuthenticated);

  useEffect(() => {
    setAuthState(isAuthenticated);
  }, [isAuthenticated]);

  const handleLogin = () => {
    setAuthState(true);
  };

  const handleLogout = () => {
    setAuthState(false);
  };

  if (!authState) {
    return <SignInSide onLogin={handleLogin} />;
  }

  return (
    <Router>
      <Main onLogout={handleLogout} />
    </Router>
  );
};

export default App;
